const publisherProjectIds: { [index: string]: number } = {
    ["ppf"]: 1,
    ["ppfo"]: 3,
    ["dof"]: 4,
    ["ykt-bowl"]: 5,
}

const playersProjectIds: { [index: string]: string } = {
    ["ppf"]: "ppf",
    ["dof"]: "dof",
    ["ykt-bowl"]: "ykt-bowl"
}

const buildsProjectIds: { [index: string]: string } = {
    ["ppf"]: "ppk",
    ["dof"]: "dof",
    ["ttt"]: "ttt",
    ["ykt-bowl"]: "ykt-bowl",
    ["prototype"]: "prototype"
}

export const GetToolAndProjectRoute = (projectId: string): string | number => {

    // This is just horrible. Never repeat this.
    // This is only here because product, rightly, want to be able to switch projects and remain in the same tool.
    // Because of previous design decisions this was not possible with the current project structure.
    // The result is this "hacky" code. Hopefully it's temporary until I can refactor this whole project into a single app.

    const getSubdomain = (url: string) => {
        let domain = url;
        if (url.includes("://")) {
            domain = url.split('://')[1];
        }
        return domain.split('.')[0];
    };

    const subdomain = getSubdomain(window.location.hostname);

    if (subdomain.includes("publisher"))
        return publisherProjectIds[projectId];
    if (subdomain.includes("players"))
        return playersProjectIds[projectId];
    if (subdomain.includes("builds"))
        return buildsProjectIds[projectId];
    else
        return publisherProjectIds[projectId];
}


